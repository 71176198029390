const options = [
  { to: '/', text: 'About' },
  { to: '/projects/', text: 'Projects' },
  {
    to: 'https://resume.creddle.io/resume/1hm5ly31gir',
    text: 'Resume',
    newTab: true,
  },
];

export default options;
